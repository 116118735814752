.Tooltip-Wrapper {
    display: inline-block;
    position: relative;
  }
  
  .Tooltip-Tip {
    position: absolute;
    border-radius: 8px;
    left: 50%;
    transform: translateX(-90%);
    background: #212121;
    z-index: 100;
    white-space: nowrap;
  }
  
  .Tooltip-Tip::before {
    content: " ";
    left: 90%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: -6px;
    bottom: 100%;
    border-bottom-color: #212121
  }